<script lang="ts" setup>
import { listContentById } from '~/composables/request'
import { CONTENT_QUERY_LIST } from '~/constants'
import type { Content } from '~/types'
import { useConfigStore } from '~/store'
import { PID } from '~/types'

const config = useConfigStore()
config.setState({
  leaderboardPID: PID.leaderboard_homepage,
  contentType: undefined,
  contentId: undefined,
  showAds: config.showAds,
})

const AsyncWrapper = defineComponent({
  name: 'AsyncWrapper',
  async setup(_, ctx) {
    const ids = initRandomIndexArray().slice(0, 5)
    const { results: list } = await listContentById('new-releases-movies', ids) // default content type

    return () => ctx.slots?.default?.({ list })
  },
})
</script>

<template>
  <div>
    <AsyncWrapper v-slot="{ list }: {list: Content[]}">
      <ClientOnly>
        <HomeSlides :list="list" />
      </ClientOnly>
    </AsyncWrapper>

    <ClientOnly>
      <CarouselWrapper v-for="(queryItem, index) in CONTENT_QUERY_LIST" :key="queryItem.type" :query-item="queryItem" :show-ad="index === 0" />
    </ClientOnly>
  </div>
</template>
